﻿import { addAriaToAllLiElements, classList, setAttributes } from '../../lib/autocomplete/utils/function'
import Autocomplete from '../../lib/autocomplete/autocomplete.esm'
import '../../lib/autocomplete/autocomplete.min.css'

import { GeoUtil } from '../util/GeoUtil'

export * from './constants/global'

// prettier-ignore
(function () {
  if (typeof window.IOL === 'undefined') {
    window.IOL = {}
  }
})()

export class IolAutocompleteDoveUnico extends Autocomplete {
  constructor(id, url, options = {}) {
    super(id, {
      ...options,

      onSearch: ({ currentValue }) => {
        currentValue = this.#getInputNormalize(currentValue)
        const postQs = this.#getQSMyPosLbs(this._mypos)
        const api = url + this.#getQsLbs(currentValue) + '&dv=' + encodeURI(currentValue) + postQs

        this.previousFlag = 0 //@TODO NO CACHE, altrimenti togliere
        return new Promise((resolve) => {
          if (currentValue.length <= 2) {
            this.previousFlag = 0
            this.previousQuery = ''
            this.previousResults = []
          }
          if (this.previousFlag === 1 && this.previousResults.length > 0) {
            // Utilizza i risultati dalla cache
            this.previousQuery = currentValue
            resolve(this.#filterCacheResults(currentValue))
          } else {
            this.previousQuery = currentValue
            // Esegui una nuova chiamata al servizio
            fetch(api)
              .then((response) => response.json())
              .then((data) => {
                this.previousFlag = 0 // this.previousFlag=data.f //@TODO CACHE
                let nresout = this._nresout
                if (this._mypos) {
                  nresout += 1
                  const mypos = this.#generateMypos(this._mypos)
                  if (mypos.position === 'top') {
                    data.r.unshift(mypos)
                  } else {
                    data.r.push(mypos)
                  }
                }
                if (data.r.length === 0) {
                  this._onResultCallBack([])
                }
                resolve(data.r.slice(0, nresout))
                //@TODO CACHE
                //this.previousResults = data.r.slice(0, nresout)
                //resolve(this.#filterCacheResults(currentValue))
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },

      onClose: () => this.onClose(),

      onReset: () => {
        this.#getInputReset()
        this.previousFlag = 0
        this.previousQuery = ''
        this.previousResults = []
      },

      onResults: ({ matches }) => {
        if (options.showResultDropdown === undefined || options.showResultDropdown) {
          return matches
            .map((item) => {
              let ret
              if (item.mypos) {
                ret = '<li class="mypos">'
              } else {
                ret = '<li>'
              }
              ret += GeoUtil.getItemDesc(item, { frazDiDesc: this._frazDiDesc }) + '</li>'
              return ret
            })
            .join('')
        } else {
          return this._onResultCallBack(matches)
        }
      },

      onSubmit: ({ index, element, object }) => {
        if (this._mypos && element.value === this._mypos.label) {
          element.value = GeoUtil.getItemDesc(this._mypos.value, {
            frazDiDesc: this._frazDiDesc
          })
          if (!this._clearBtnMyPos) this._clearBtnMyPos = this._clearBtn

          if (this._mypos.onclick) this._mypos.onclick()

          // gestione evento click sul clear per mypos la prima volta (con {once: true})
          this._clearBtnMyPos.addEventListener(
            'click',
            () => {
              element.value = ''
              this.lat = null
              this.lon = null
              this.desc = ''
              classList(this._clearBtnMyPos, 'add', 'hidden')
            },
            { once: true }
          )

          this.lat = object?.value?.lat || null
          this.lon = object?.value?.lon || null
          this.element = object?.value || null
        } else {
          this.lat = object?.lat || null
          this.lon = object?.lon || null
          this.element = object || null
        }
        this.desc = element.value
        this._onSubmitCallBack && this._onSubmitCallBack()
      }
    })

    // attributi oggetto autocomplete (valorizzato ai valori della voce selezionata)
    this.id = id

    // @option parametri di ricerca verso LBS
    this._opentipo = options.opentipo || IOL.geo.OPENTIPO_INIT_WORD
    this._sito = options.sito || IOL.geo.SITO_API
    this._st = options.st || IOL.geo.AREA_ITALY
    this._lg = options.lg || IOL.geo.LNG_ITALIAN
    this._topotipo = options.topotipo || ''
    this._client = options.client || ''
    this._nresout = options.nresout || 10
    this._nresac = options.nresac || 50
    this._frazDiDesc = options.frazDiDesc || ' frazione di '
    // @option initCallBack eventuale callback da eseguire dopo il primo geocoding fatto sul valore di default dell'input
    this._initCallBack = options.initCallBack || null
    this._onSubmitCallBack = options.onSubmitCallBack || null
    this._onResetCallBack = options.onResetCallBack || null
    this._onResultCallBack = options.onResultCallBack || null

    // gestione valore di default della input box
    this.url = url
    const defaultValue = document.getElementById(id).value
    if (defaultValue) {
      this.#geocodingInputValue(defaultValue, this._initCallBack)
    } else {
      this.#getInputReset()
    }

    // @option mypos
    // aggiunge mia posizione, JSON type
    // {
    //	position: posizionamento voce mia posizione top o bottom, default top
    //	openOnFocus: booleano che indica se aprire o no la dropdown con la sola voce mypos sul focus del campo di input, default false
    //	label: 'testo da visualizzare nella dropdown' default 'VICINO A ME'
    //	value: JSON dell'oggetto della geocodifica inversa ottenuta dal servizio LBS, es.:
    //			{
    //				"ret": 0,
    //				"codQuartiere": 900003001,
    //				"descQuartiere": "Villapizzone",
    //				"linkid": 955027744,
    //				"stato": "Italia",
    //				"codStato": 20110484,
    //				"reg": "Lombardia",
    //				"codReg": 7,
    //				"codIstatReg": "03",
    //				"dsProv": "Milano",
    //				"prov": "MI",
    //				"codProv": 50,
    //				"codIstatProv": "015",
    //				"com": "Milano",
    //				"codCom": 51557,
    //				"codIstatCom": "015146",
    //				"hostname": "milano",
    //				"dug": "Piazza",
    //				"ndTopo": "Prealpi",
    //				"topo": "Piazza Prealpi",
    //				"cod_strada": 1139046,
    //				"toponimo": "Piazza Prealpi",
    //				"pop": 1366180,
    //				"lat": 45.49459,
    //				"lon": 9.15345,
    //				"dist": 11
    //			}
    //		class: nome classe da associare all' <li> del mypos default mypos
    //		onclick: function da associare all'evento onclick dell'li del mypos
    // }
    if (options.mypos) {
      this.updateMypos(options.mypos)
    }

    // Cache initialization
    this.previousQuery = ''
    this.previousResults = []
    this.previousFlag = 0
  }

  // public method
  updateMypos(mypos) {
    this._mypos = this.#generateMypos(mypos)

    if (this._mypos && this._mypos.openOnFocus === true) {
      // Aggiungi il listener per l'evento focus
      document.getElementById(this.id).addEventListener('focus', () => {
        setTimeout(() => {
          setAttributes(this._root, {
            'aria-expanded': 'true',
            addClass: this._prefix + '-expanded'
          })

          this._resultList.innerHTML = '<li class="' + this._mypos.class + '">' + this._mypos.label + '</li>'

          classList(this._resultWrap, 'add', this._isActive)
          this._itemsLi = document.querySelectorAll('#' + this._outputUl + ' > li')
          addAriaToAllLiElements(this._itemsLi)
          this._matches = [this._mypos]
          this._events()
        }, 1000)
      })
    }
  }

  // public method
  onClose() {
    this._resultList.textContent = ''
  }

  // public method destroy chiamato erase perchè destroy() del padre è freccia e non può essere sovrascritta
  erase() {
    this.destroy()
    let inputElement = document.getElementById(this.id)
    let resultsWrapper = inputElement.nextElementSibling
    resultsWrapper.innerHTML = ''
  }

  // Cache private methods
  #filterCacheResults(currentValue) {
    const filteredResults = this.previousResults.filter((item) => GeoUtil.getItemDesc(item, { frazDiDesc: this._frazDiDesc }).toLowerCase().includes(currentValue.toLowerCase()))
    console.log('Filtered results for:', currentValue, filteredResults)
    return filteredResults
  }

  // private method
  #getQSMyPosLbs(mypos) {
    if (mypos) {
      if (mypos.value !== undefined) {
        return '&cx=' + mypos.value.lon + '&cy=' + mypos.value.lat + '&mr=25'
      }
    }
    return ''
  }

  // private method
  #generateMypos(mypos) {
    const ret = {}

    ret.mypos = true
    ret.position = mypos.position || 'top'
    ret.openOnFocus = mypos.openOnFocus || false
    ret.label = mypos.label || 'VICINO A ME'
    if (mypos.value) {
      ret.value = mypos.value
    } else {
      throw new Error("L'attributo value del oggetto mypos è obbligatorio")
    }
    ret.class = mypos.class || 'mypos'
    if (mypos.onclick) ret.onclick = mypos.onclick

    return ret
  }

  // private method
  #geocodingInputValue(inputValue, initCallBack) {
    inputValue = this.#getInputNormalize(inputValue)
    const api = this.url + this.#getQsLbs(inputValue, true) + '&dv=' + encodeURI(inputValue)

    return new Promise((resolve) => {
      fetch(api)
        .then((response) => response.json())
        .then((data) => {
          if (data.r[0]) {
            this.lat = data.r[0].lat
            this.lon = data.r[0].lon
            this.desc = GeoUtil.getItemDesc(data.r[0], {
              frazDiDesc: this._frazDiDesc
            })
            document.getElementById(this.id).value = this.desc
            this.element = data.r[0]

            // gestione clear button
            this._clearBtn.classList.remove('hidden')
            // gestione evento click sul clear
            this._clearBtn.addEventListener(
              'click',
              () => {
                document.getElementById(this.id).value = ''
                this.lat = null
                this.lon = null
                classList(this._clearBtn, 'add', 'hidden')
              },
              { once: true }
            )

            if (initCallBack != null) initCallBack()
          } else {
            this.#getInputReset()
          }
          resolve(data.r)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  // private method
  #getQsLbs(currentValue, defaultValue = false) {
    let qs

    /*
    if (this.previousQuery && currentValue.indexOf(this.previousQuery) !== 0) {
      // Cambiato in modo significativo, resetto la cache e imposto opentipo a INIT_WORD
      //this.previousFlag = 0
      this._opentipo = IOL.geo.OPENTIPO_INIT_WORD
    } else {
      this._opentipo = IOL.geo.OPENTIPO_INIT_WORD //IOL.geo.OPENTIPO_CASCADE
    }
		*/

    this._opentipo = IOL.geo.OPENTIPO_INIT_WORD

    if (defaultValue) {
      qs = '?opentipo=-1'
      qs += '&nresout=1'
      qs += '&nresac=1'
    } else {
      qs = '?opentipo=' + this._opentipo
      qs += '&nresout=' + this._nresout
      qs += '&nresac=' + this._nresac
    }
    qs += '&sito=' + this._sito
    qs += '&st=' + this._st
    qs += '&lg=' + this._lg
    qs += '&topotipo=' + this._topotipo
    qs += '&client=' + this._client
    return qs
  }

  // private method
  #getInputNormalize(input) {
    return input.replace(/\\\(/g, '(').replace(/\\\)/g, ')') // per qualche strana ragione la libreria su ( e ) inserisce prima un backslash
  }

  // private method
  #getInputReset() {
    this.lat = null // latitudine
    this.lon = null // longitudine
    this.desc = '' // descrizione visualizzata nel input dove
    this.element = null // l'oggetto json completo selezionato e restituito dal servizio LBS
    this._onResetCallBack && this._onResetCallBack()
  }
}

export function autocomplete(id, url, options) {
  return new IolAutocompleteDoveUnico(id, url, options)
}

IOL.autocompleterDoveUnico = autocomplete
